import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const CookiesPage = () => (
    <Layout>
        <SEO title="Polityka Cookies | Świecki Kancelaria"/>
        <section className="polityka">
            <div className="polityka__container">
                <h1 className="polityka__title">Polityka cookies</h1>
                <ul className="polityka__list">
                    <li className="polityka__item item">
                        <h2 className="item__title">&#167;1 Pliki cookie</h2>
                        <p className="item__text">
                            Pliki cookie to informacje przechowywane na komputerze Użytkownika
                            wykorzystywane do dostosowywania usług internetowych. Strona internetowa
                            wykorzystuje pliki cookie do przechowywania informacji na temat Użytkowników,
                            które mogą być potrzebne w celu późniejszego wykonania określonych funkcji.
                            Użytkownicy odwiedzający stronę internetową Administratora mogą wyłączyć pliki
                            cookie w ustawieniach przeglądarki.
                        </p>
                    </li>
                    <li className="polityka__item item">
                        <h2 className="item__title">&#167;2 Cel wykorzystywania
                        </h2>
                        <p className="item__text">
                            Pliki cookie wykorzystywane są do analizy ruchu na stronie. Informacje o
                            korzystaniu ze strony internetowej Administratora przekazywane są również do
                            partnerów zajmujących się analizą witryn internetowych pod kątem ruchu
                            użytkowników.
                        </p>
                    </li>
                    <li className="polityka__item item">
                        <h2 className="item__title">&#167;3 Wyłączenie plików cookie</h2>
                        <p className="item__text">
                            Użytkownik ma możliwość skonfigurowania przeglądarki lub strony internetowej w
                            sposób ograniczający lub blokujący pliki cookie, co będzie mieć jednak wpływ na
                            prawidłowość działania witryny internetowej jak i ewentualne ograniczenie
                            niektórych bądź wszystkich jej funkcji. Dodatkowe informacje na temat plików
                            cookie oraz instrukcje, jak dostosować ustawienia przeglądarki w celu
                            przyjmowania, kasowania lub odrzucania tych plików dostępne są pod adresem
                            www.allaboutcookies.org
                        </p>
                    </li>
                </ul>
            </div>
        </section>
    </Layout>
)

export default CookiesPage